<template>
	<SectionCard class="cassie-configure-preference-page__section-card">
		<template #title>
			Link Fields
		</template>
		<template #title-action>
			<TextButton
				:disabled="!userFullPermissions"
				@click="addLinkField"
			>
				Add Link Field
			</TextButton>
		</template>
		<template #body>
			<LinkFieldTable
				:link-fields="linkFields"
				:available-fields="availableFields"
				:user-full-permissions="userFullPermissions"
				@remove-link-field="onRemoveLinkField"
				@update-link-fields-row="updateLinkFieldsRow"
			/>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import LinkFieldTable from './link-field-table.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'

export default {
	components: {
		SectionCard,
		LinkFieldTable,
		TextButton
	},
	props: {
		linkFields: Array,
		availableFields: Array,
		userFullPermissions: Boolean
	},
	methods: {
		addLinkField () {
			this.$emit('add-link-field')
		},
		onRemoveLinkField (index) {
			this.$emit('remove-link-field', index)
		},
		updateLinkFieldsRow (index, field, value) {
			this.$emit('update-link-fields-row', index, field, value)
		}
	}
}
</script>
